import { ThemeProvider } from '@mui/material';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import * as css from '../../pages/index.module.css';
import { theme } from '../../themes';

const PageTemplate = ({ data, pageContext, locale }: any) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className={css.container}>
        <h1 className={css.title}>{data.mdx.frontmatter.title}</h1>
        <div className={css.intro}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    mdx(frontmatter: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
      }
      body
    }
  }
`;
